<template>
  <div>
    <h1 class="uk-text-left uk-margin-small-top"><a @click="$router.go(-1)" style="text-decoration: none;" class="uk-flex-auto"><span uk-icon="icon: chevron-left; ratio: 2.2"></span><span >{{ $t('Pages.UserPermissions.Title') }}</span></a></h1>
    <hr />

    <div class="uk-grid-divider" uk-grid>
      <div class="uk-width-expand@m">
        <div class="uk-container uk-margin-medium-top">
          <baseTable
            :tableHeaders="tableHeaders"
            :totalCount="permissions.length"
          >
            <tr 
              v-for="(permission, i) in permissions.filter(perm => perm.property)"
              :key="i"
            >
              <td> <a @click="navigateToDashboard(permission.property.organization.id)"> {{ permission.property.organization && permission.property.organization.name }} </a> </td>
              <td class="uk-text-truncate uk-width-1-2"> <a @click="navigateToFolder(permission.property)">{{ permission.property.i18nName&& permission.property.i18nName.all[0].value }} </a> </td>
              <td><i v-if="permission.property.active" uk-icon="check"></i></td>
              <td><span class="uk-label">{{ permission.type }}</span></td>
            </tr>
          </baseTable>
        </div>
      </div>
  
      <div class="uk-width-1-3">
        <h3>{{$t('Pages.Organization.Organizations')}}</h3>
        <div class="uk-container">
          <div class="uk-tile uk-tile-primary uk-border-rounded uk-padding-small">
            <div class="uk-tite-title">{{$t('Pages.UserPermissions.MemberOf')}}</div>
            <div class="uk-tite-body"><router-link :to="{ name: 'Organizations' }">{{ organizations.length }} Organizations</router-link></div>
          </div> 
        </div>

        <h3>{{$t('Pages.UserPermissions.Folders')}}</h3>
        <div class="uk-container">
          <div class="uk-tile uk-tile-primary uk-border-rounded uk-padding-small">
            <div class="uk-tite-title">{{$t('Pages.UserPermissions.AccessTo')}}</div>
            <div class="uk-tite-body">{{ permissions.length }} {{$t('Pages.UserPermissions.Folders')}}</div>
          </div> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BaseTable from '@/components/tables/BaseTable.vue';

export default {
  name: 'UserPermissions',
  components: {
    BaseTable
  },
  computed: {
    ...mapState({
      permissions: state => state.venom?.user.permissions || []
    }),
    ...mapGetters([
      'organizations'
    ]),
    tableHeaders() {
      return [{
          name: this.$t('Pages.UserPermissions.Organization'),
          class: 'uk-preserve-width'
        }, {
          name: this.$t('Pages.UserPermissions.Folder'),
          class: 'uk-text-truncate'
        }, {
          name: this.$t('Pages.UserPermissions.IsActive'),
          class: 'uk-width-small'
        }, {
          name: this.$t('Pages.UserPermissions.Type'),
          class: 'uk-width-medium'
        }];
    }
  },
  methods: {
    async navigateToDashboard (orgId) {
      await this.$store.dispatch('getOrgPermissions', orgId );
      this.$router.push({ name: 'Dashboard', params: { id: orgId }})
    },
    async navigateToFolder (property) {
      await this.$store.dispatch('getOrgPermissions', property.organization.id );
      this.$router.push({ name: 'Collections', params: { id: property.organization.id, propertyId: property.id, propertyName: property.i18nName&& property.i18nName.all[0].value }})
    }
  },
  mounted() {
  }
}
</script>
